import theme from '@mdb/flora/theme';
import { SectionIntro } from '@mdb/flora';
import Notification from '../Notification';
import parse from 'html-react-parser';

const NonStudentHeader = ({ name }: { name: string }) => {
    return (
        <div sx={{ margin: `${theme.space.section50} auto` }}>
            <SectionIntro
                title={
                    parse(
                        `<span data-test="non-student-msg" style="color:${theme.colors.green50}">Hi ${name}!</span> You are not currently registered for the GitHub Student Pack`
                    ) as string
                }
            />
            <Notification
                type="warn"
                message="Welcome and thanks for logging in. Unfortunately we are unable to verify your status as a participant in the GitHub Student Developer Pack. Please visit the GitHub Student Pack home for additional information and how to register."
            />
            <SectionIntro
                title=""
                buttonText="GitHub Student Pack Home"
                buttonHref="https://education.github.com/pack"
                buttonTarget="_blank"
            />
        </div>
    );
};

export default NonStudentHeader;
