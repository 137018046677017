import { signIn } from 'next-auth/react';
import theme from '@mdb/flora/theme';
import { Button, Link, SectionIntro } from '@mdb/flora';
import parse from 'html-react-parser';

const LoggedOutHeader = () => (
    <div
        sx={{
            marginTop: ['inc80', null, null, 'section50'],
            marginBottom: ['inc100', null, null, 'section50'],
        }}
    >
        <SectionIntro
            title={
                parse(
                    `<span style="color:${theme.colors.green50}">Get $200</span> of Benefits with the GitHub Student Developer Pack`
                ) as string
            }
            text="Get free certification and $50 of Atlas credits for the leading modern, general purpose database platform by signing up for the GitHub Student Developer Pack."
        />
        <div
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                marginTop: 'inc60',
            }}
        >
            <div
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button
                    sx={{
                        margin: 'inc30',
                        width: 'unset',
                    }}
                    size="small"
                    onClick={() => {
                        signIn('github');
                    }}
                >
                    Sign in with GitHub
                </Button>
                <Link
                    linkIcon="arrow"
                    href="https://education.github.com/pack"
                    target="_blank"
                >
                    GitHub Student Developer Pack
                </Link>
            </div>
        </div>
    </div>
);

export default LoggedOutHeader;
