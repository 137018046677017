import { FlashCard, IFlashCardProps } from '@mdb/flora';

import { Carousel } from '@mdb/lms-components';
import { TrackDetails } from 'keen-slider';
import { useState } from 'react';

const borderStyle = (details: TrackDetails | null, idx: number) => {
    if (!details) return {};
    const slide = details.slides[idx];

    if (!slide) return {};

    const borderOpacity = 1 / (3 * (Math.abs(slide.distance - 1 / 3) * 15) + 1);
    const borderOpacityMobile = 1 / (3 * Math.abs(slide.distance) + 1);

    return {
        border: [
            `1px solid rgba(2,52,48,${borderOpacityMobile})`,
            null,
            `1px solid rgba(2,52,48,${borderOpacity})`,
        ],
    };
};

const FeaturedCarousel = ({ cards }: { cards: IFlashCardProps[] }) => {
    const [trackDetails, setTrackDetails] = useState<TrackDetails | null>(null);

    return (
        <Carousel
            setTrackDetails={setTrackDetails}
            paginationInverse
            styles={{
                maxWidth: 1400,
                mx: 'auto',
                width: '100%',
                marginBottom: ['inc90', null, '100px', null, 'inc120'],
                '.keen-slider__slide > div > div': {
                    padding: [
                        '20px 20px 20px 0 !important',
                        null,
                        '0 !important',
                    ],
                    overflow: 'visible !important',
                },
                '.carousel-control': {
                    display: ['none', null, 'flex'],
                },
            }}
        >
            {cards.map((flashCardProps: IFlashCardProps, idx: number) => (
                <FlashCard
                    key={idx}
                    {...flashCardProps}
                    customVariant="heading6"
                    imageryType="image"
                    customLinkStyles={{
                        width: 'auto',
                        minHeight: 'auto',
                        height: '100%',
                        boxSizing: 'border-box',
                        flexWrap: 'nowrap',
                        span: { lineHeight: '20px !important' },
                        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
                        ...borderStyle(trackDetails, idx),
                    }}
                    customContentStyles={{ width: 'auto' }}
                    customWrapperStyles={{ height: '100%' }}
                    customHeaderStyles={{ img: { height: 177 } }}
                />
            ))}
        </Carousel>
    );
};

export default FeaturedCarousel;
