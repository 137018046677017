import { useState, useEffect } from 'react';
import theme from '@mdb/flora/theme';
import { SectionIntro } from '@mdb/flora';
import Notification from '../Notification';
import parse from 'html-react-parser';
import { useBasePath } from '../../lib/path';

const StudentHeader = ({ name }: { name: string }) => {
    const [receiveEmails, setReceiveEmails] = useState(false);
    const [displayReceiveEmailsConsent, setDisplayReceiveEmailsConsent] =
        useState(false);

    const apiUserPath = useBasePath('/api/user');

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(apiUserPath);
            const { emailPermission } = await response.json();
            setReceiveEmails(emailPermission);
            if (!emailPermission) {
                setDisplayReceiveEmailsConsent(true);
            }
        };
        fetchData();
    }, []);

    const handleCloseReceiveEmailConsentMessage = () => {
        setDisplayReceiveEmailsConsent(false);
    };

    const toggleReceiveEmails = async (checked: boolean) => {
        setReceiveEmails(checked);
        const response = await fetch(apiUserPath, {
            method: 'POST',
            body: JSON.stringify({ emailPermission: checked }),
        });
        const { user, error } = await response.json();
        if (user) {
            setReceiveEmails(user.emailPermission);
        } else if (error) {
            setReceiveEmails(!checked);
        }
    };

    const messageMapper = {
        request:
            'Allow MongoDB to send updates on relevant topics like internships, student offers, event discounts and more.',
        thankYou: 'Thank you for enabling MongoDB Student emails!',
    };

    return (
        <>
            {displayReceiveEmailsConsent && (
                <Notification
                    type="success"
                    displayIcon={false}
                    message={
                        receiveEmails
                            ? messageMapper.thankYou
                            : messageMapper.request
                    }
                    close={handleCloseReceiveEmailConsentMessage}
                    action={{
                        name: 'email-consent',
                        type: 'checkbox',
                        handler: toggleReceiveEmails,
                        value: receiveEmails,
                    }}
                />
            )}
            <div sx={{ margin: `${theme.space.section50} auto` }}>
                <SectionIntro
                    title={
                        parse(
                            `<span style="color:${theme.colors.green50}">Hi ${name}!</span> Access Your MongoDB Benefits`
                        ) as string
                    }
                />
            </div>
        </>
    );
};

export default StudentHeader;
