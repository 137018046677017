import theme from '@mdb/flora/theme';
import {
    Checkbox,
    TypographyScale,
    SystemIcon,
    ESystemIconNames,
} from '@mdb/flora';

const iconMapper = {
    success: ESystemIconNames.CIRCLE_CHECK,
    info: ESystemIconNames.CIRCLE_INFO,
    warn: ESystemIconNames.CIRCLE_ALERT,
    error: ESystemIconNames.CIRCLE_ALERT,
};

const colorMapper = {
    success: { background: theme.colors.green10, border: theme.colors.black20 },
    info: { background: theme.colors.blue10, border: theme.colors.blue20 },
    warn: { background: theme.colors.yellow10, border: theme.colors.yellow20 },
    error: { background: theme.colors.red10, border: theme.colors.red20 },
};

type NotificationProps = {
    type: 'success' | 'info' | 'warn' | 'error';
    displayIcon?: boolean;
    heading?: string;
    message: string;
    close?: () => void;
    action?: {
        name: string;
        type: 'checkbox';
        handler: (value: any) => void;
        value: boolean;
    };
};
const Notification = ({
    type,
    displayIcon = true,
    heading = '',
    message,
    close,
    action,
}: NotificationProps) => {
    return (
        <div
            data-test="notification-banner"
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                backgroundColor: colorMapper[type].background,
                border: `1px solid ${colorMapper[type].border}`,
                padding: theme.space.inc30,
                boxShadow: theme.shadows.level01,
                borderRadius: theme.radii.inc20,
                maxWidth: '900px',
                margin: '1em auto',
                textAlign: 'left',
            }}
        >
            {action && action.type === 'checkbox' && (
                <Checkbox
                    name={action.name}
                    onToggle={checked => action.handler(checked)}
                    checked={action.value}
                />
            )}
            {displayIcon && (
                <SystemIcon
                    name={iconMapper[type]}
                    size="large"
                    sx={{ marginRight: '10px' }}
                />
            )}
            <div sx={{ display: 'flex', flexDirection: 'column' }}>
                <TypographyScale variant="body1">{heading}</TypographyScale>
                <TypographyScale variant="body3">{message}</TypographyScale>
            </div>
            {close && (
                <a onClick={close} sx={{ marginLeft: '8px' }}>
                    <SystemIcon name={ESystemIconNames.CLOSE} />
                </a>
            )}
        </div>
    );
};

export default Notification;
