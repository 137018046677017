import React, { ReactElement } from 'react';
import Image from 'next/image';
import { Button, Link, TargetType, TypographyScale } from '@mdb/flora';
import theme from '@mdb/flora/theme';
import { ImageLoaderProps } from 'next/image';
import parseHelper from '../utils/parseHelper';

interface CTA {
    type: 'button' | 'component' | 'link';
    text?: string;
    component?: ReactElement;
    config: {
        variant?: 'chevron' | 'arrow' | 'chevron-left';
        href?: string;
        target?: TargetType;
    };
}

interface Thumbnail {
    url: string;
    alt: string;
}

export interface FlashCardProps {
    title: string;
    description?: string | ReturnType<typeof parseHelper>;
    listItems?: string[] | ReturnType<typeof parseHelper>[];
    cta: CTA;
    thumbnail: Thumbnail;
}

const thumbnailLoader = ({ src, width, quality }: ImageLoaderProps) => {
    return `${src}?w=${width}&q=${quality || 90}`;
};

const FlashCard: React.FunctionComponent<FlashCardProps> = ({
    description,
    title,
    thumbnail,
    cta,
    listItems,
}) => {
    return (
        <div
            sx={{
                position: 'relative',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: [
                    theme.space.cards.paddingXXS,
                    null,
                    null,
                    theme.space.cards.paddingXS,
                ],
                height: '100%',
                border: `1px solid ${theme.colors.card.default.border}`,
                boxShadow: 'level01',
                borderRadius: 'inc50',
                textAlign: 'center',
                width: '90%',
                backgroundColor: theme.colors.card.default.bg,
            }}
        >
            <div
                sx={{
                    display: 'flex',
                    flexDirection: ['column', null, 'column'],
                }}
            >
                <div
                    sx={{
                        display: 'block',
                        flexShrink: 0,
                        position: 'relative',
                        width: '180px',
                        height: '180px',
                        margin: '0 auto',
                    }}
                >
                    <Image
                        alt={thumbnail.alt || 'alt not provided'}
                        loader={thumbnailLoader}
                        src={thumbnail.url}
                        sx={{
                            borderRadius: 'inc30',
                            objectFit: 'cover',
                        }}
                        layout="fill"
                    />
                </div>
            </div>
            <TypographyScale
                sx={{
                    fontSize: `${theme.fontSizes.inc50} !important`,
                    lineHeight: `${theme.lineHeights.inc30} !important`,
                }}
                variant="heading5"
            >
                {title}
            </TypographyScale>
            <div>
                <div
                    sx={{
                        marginTop: ['inc30', null, null, 'inc30'],
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        textAlign: 'center',
                        color: theme.colors.blue80,
                        fontSize: theme.fontSizes.inc20,
                        lineHeight: theme.lineHeights.inc30,
                        opacity: theme.opacity.inc80,
                        marginBottom: '1em',
                    }}
                >
                    {!!description && <p>{description}</p>}
                    {!!listItems?.length && (
                        <div>
                            {listItems.map((item, i) => {
                                return <p key={i}>{item}</p>;
                            })}
                        </div>
                    )}
                </div>
                <div sx={{ marginBottom: 'inc50' }}>
                    {cta.type === 'link' && (
                        <Link
                            sx={{ display: 'inline-block' }}
                            linkIcon={cta.config.variant}
                            href={cta.config.href}
                            target={cta.config.target || '_self'}
                        >
                            {cta.text}
                        </Link>
                    )}
                    {cta.type === 'button' && (
                        <Button sx={{ width: 'unset' }} href={cta.config.href}>
                            {cta.text}
                        </Button>
                    )}
                    {cta.type === 'component' && <div>{cta.component}</div>}
                </div>
            </div>
        </div>
    );
};

export default FlashCard;
