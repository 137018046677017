import parse from 'html-react-parser';
import theme from '@mdb/flora/theme';
import { TargetType } from '@mdb/flora';

type ModifierProps = {
    type: 'link' | 'strong';
    index: number;
    href?: string;
    target?: TargetType;
    id?: string;
};
const parseHelper = (message: string[], modifiers: ModifierProps[]) => {
    modifiers.forEach(modifier => {
        let modifiedPortion;
        if (modifier.type === 'link') {
            const target = modifier.target || '_self';
            modifiedPortion = `<a ${
                modifier.id ? `id="${modifier.id}"` : ''
            } style="color:${theme.colors.blue60}" href="${
                modifier.href
            }" target="${target}" data-track="true"> ${
                message[modifier.index]
            } </a>`;
        } else if (modifier.type === 'strong') {
            modifiedPortion = `<strong> ${message[modifier.index]} </strong>`;
        } else {
            modifiedPortion = message[modifier.index];
        }
        message[modifier.index] = modifiedPortion;
    });
    return parse(message.join(''));
};

export default parseHelper;
