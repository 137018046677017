import { useContext } from 'react';
import { Button, ESystemIconNames, SystemIcon } from '@mdb/flora';
import { PathwayContext, PathwayProps } from '../context/pathway-context';
import theme from '@mdb/flora/theme';
import { Copy } from './icons/Copy';

interface Props {
    codePath: string;
    accessCode: string;
    isCopied: boolean;
    buttonLabel: string;
    setAccessCode: (code: string) => void;
    setError: (error: string) => void;
    setIsCopied: (arg: boolean) => void;
}

export const AccessCodeCTA = ({
    codePath,
    accessCode,
    isCopied,
    buttonLabel,
    setAccessCode,
    setError,
    setIsCopied,
}: Props) => {
    const pathway = useContext<PathwayProps>(PathwayContext);

    const requestAccessCode = async () => {
        const response = await fetch(codePath);
        const { code, error } = await response.json();
        if (code) {
            setAccessCode(code);
        } else if (error) {
            setError(error);
        }
    };

    const copyToClipboard = async () => {
        if (typeof navigator !== undefined) {
            try {
                await navigator.clipboard.writeText(accessCode);
                setIsCopied(true);

                pathway.track('Internal Click', {
                    properties: {
                        title: 'copy',
                        label: 'copy access code',
                        category: 'CTA click',
                    },
                });

                // resets the state of isCopied to tell the browser to remove the has been copied icon and render the can be copied icon
                window.setTimeout(() => {
                    setIsCopied(false);
                }, 3000);
            } catch (error) {
                console.error('clipboard is not accessible');
            }
        }
    };

    if (accessCode) {
        return (
            <div
                data-test="code-container"
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: theme.colors.black10,
                    padding: '24px',
                    borderBottom: `4px solid ${theme.colors.blue80}`,
                    alignItems: 'center',
                    textAlign: 'start',
                }}
            >
                {accessCode}
                {isCopied ? (
                    <SystemIcon
                        name={ESystemIconNames.CIRCLE_CHECK}
                        size="medium"
                    />
                ) : (
                    <button
                        sx={{
                            background: 'transparent',
                            border: 'none',
                            paddingRight: '0',
                            ':hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onClick={copyToClipboard}
                    >
                        <Copy />
                    </button>
                )}
            </div>
        );
    } else {
        return <Button onClick={requestAccessCode}>{buttonLabel}</Button>;
    }
};
