import type { GetServerSideProps, NextPage } from 'next';
import { getSession } from 'next-auth/react';
import { useState } from 'react';
import theme from '@mdb/flora/theme';
import { Accordion, Button, Link, TypographyScale } from '@mdb/flora';

import Notification from '../components/Notification';
import FlashCard from '../components/FlashCard';
import { AccessCodeCTA } from '../components/AccessCodeCTA';
import CardGrid from '../components/CardGrid';
import FeaturedCarousel from '../components/FeaturedCarousel';
import {
    LoggedOutHeader,
    NonStudentHeader,
    StudentHeader,
} from '../components/headers';
import type { FlashCardProps } from '../components/FlashCard';
import parseHelper from '../utils/parseHelper';
import { commonLinks, faqTabs, featureProjectCards } from '../constants';
import { useBasePath } from '../lib/path';

interface Props {
    session: {
        user: {
            name: string;
            email: string;
            image: string;
        };
        expires: string;
        isStudent: boolean;
        userId: string;
    };
}

const Home: NextPage<Props> = props => {
    const { session } = props;
    const isStudent = session ? session.isStudent : false;
    const cardsToDisplay = isStudent ? 'student' : 'nonStudent';

    const [accessCode, setAccessCode] = useState('');
    const [error, setError] = useState('');
    const [isCopied, setIsCopied] = useState(false);

    const codePath = useBasePath('/api/code');

    interface FlashCards {
        student: FlashCardProps[];
        nonStudent: FlashCardProps[];
    }

    const flashCards: FlashCards = {
        student: [
            {
                title: 'Receive $50 Atlas Credit',
                listItems: [
                    '1. Request your Atlas credit below.',
                    parseHelper(
                        [
                            '2. Apply the code to your organization in Atlas within 12 months.',
                            'Full instructions here.',
                        ],
                        [
                            {
                                type: 'link',
                                index: 1,
                                href: commonLinks.promoCodeInstructions,
                            },
                        ]
                    ),
                ],
                cta: {
                    type: 'component',
                    config: {},
                    component: (
                        <AccessCodeCTA
                            codePath={codePath}
                            accessCode={accessCode}
                            isCopied={isCopied}
                            buttonLabel="Request your Atlas code"
                            setAccessCode={setAccessCode}
                            setError={setError}
                            setIsCopied={setIsCopied}
                        />
                    ),
                },
                thumbnail: {
                    url: 'https://gh-student-pack-images.s3.amazonaws.com/Atlas.svg',
                    alt: 'atlas',
                },
            },
            {
                title: 'Claim Your Free MongoDB Certification',
                listItems: [
                    parseHelper(
                        [
                            '1. Complete an entire',
                            'Certification Learning Path',
                            'on MongoDB University.',
                        ],
                        [
                            {
                                id: 'cert-lp',
                                type: 'link',
                                index: 1,
                                href: commonLinks.certificationLearningPaths,
                                target: '_blank',
                            },
                        ]
                    ),
                    parseHelper(
                        [
                            '2. You will receive an email with a 100% discount voucher within 24 hours of completing an entire Certification Learning Path. If you do not receive your code after 24 hours, please complete',
                            'this form.',
                        ],
                        [
                            {
                                id: 'google-form',
                                type: 'link',
                                index: 1,
                                href: commonLinks.certificationForm,
                                target: '_blank',
                            },
                        ]
                    ),
                ],
                cta: {
                    type: 'button',
                    text: 'Start Learning with MongoDB University',
                    config: {
                        href: commonLinks.startLearningWithUniversity,
                    },
                },
                thumbnail: {
                    url: 'https://gh-student-pack-images.s3.amazonaws.com/Working.svg',
                    alt: 'working',
                },
            },
        ],
        nonStudent: [
            {
                title: '$50 Atlas Credit',
                description:
                    'MongoDB Atlas is the most advanced cloud database service on the market. Atlas free clusters provide a small-scale development environment to host your data. Start building your project now on our Free Cluster or create a paid cluster with this $50 Atlas credits GitHub student pack perk!',
                cta: {
                    type: 'link',
                    text: 'Learn more about Atlas',
                    config: {
                        variant: 'chevron',
                        href: commonLinks.learnMoreAboutAtlas,
                        target: '_blank',
                    },
                },
                thumbnail: {
                    url: 'https://gh-student-pack-images.s3.amazonaws.com/Atlas.svg',
                    alt: 'atlas',
                },
            },
            {
                title: 'Free MongoDB Certification ($150 value)',
                description: parseHelper(
                    [
                        'Certifications offered by MongoDB are recognized by professional institutions and the worldwide tech community, as representing a mastery level in MongoDB applications. Complete a',
                        'certification learning path',
                        'and exam to enrich your resume with a free certification!',
                    ],
                    [
                        {
                            type: 'link',
                            index: 1,
                            href: commonLinks.certificationLearningPaths,
                            target: '_blank',
                        },
                    ]
                ),
                cta: {
                    type: 'link',
                    text: 'Learn more about MongoDB Certifications',
                    config: {
                        variant: 'chevron',
                        href: commonLinks.certification,
                        target: '_blank',
                    },
                },
                thumbnail: {
                    url: 'https://gh-student-pack-images.s3.amazonaws.com/Working.svg',
                    alt: 'working',
                },
            },
        ],
    };

    return (
        <>
            {session?.user && isStudent && (
                <StudentHeader name={session.user.name} />
            )}
            {session?.user && !isStudent && (
                <NonStudentHeader name={session.user.name} />
            )}
            {!session && <LoggedOutHeader />}

            {error && (
                <Notification
                    type="error"
                    heading="We have a little problem."
                    message={error}
                />
            )}

            <CardGrid>
                {flashCards[cardsToDisplay].map(card => (
                    <FlashCard
                        key={card.title}
                        title={card.title}
                        listItems={card.listItems || []}
                        description={card.description || ''}
                        cta={card.cta}
                        thumbnail={card.thumbnail}
                    />
                ))}
            </CardGrid>

            <div
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 'inc50',
                }}
            >
                <div
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 'inc30',
                    }}
                >
                    <TypographyScale variant="heading4">
                        Featured Student Projects
                    </TypographyScale>

                    <TypographyScale
                        variant="body1"
                        sx={{ textAlign: 'center' }}
                    >
                        Explore MongoDB-powered application development with
                        these student-created projects
                    </TypographyScale>
                </div>

                <div
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: ['column', null, 'row'],
                        gap: ['inc30', null, 'inc50'],
                        marginBottom: ['inc30', null, 'inc70'],
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Button
                        href="https://www.mongodb.com/developer/search/?s=&contributedBy=Student&sortMode=0"
                        customWrapperStyles={{
                            a: { width: 'auto', textAlign: 'center' },
                        }}
                        variant="primary"
                    >
                        View all Developer Center student projects
                    </Button>

                    <Link
                        linkIcon="arrow"
                        href="https://devpost.com/software/search?query=is%3Awinner+mongodb"
                        sx={{
                            span: { lineHeight: '20px !important' },
                            px: ['inc50', null, 0],
                        }}
                    >
                        View all Major League Hacking winning projects
                    </Link>
                </div>

                <FeaturedCarousel cards={featureProjectCards} />
            </div>

            <div
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                }}
            >
                <TypographyScale
                    variant="heading4"
                    sx={{
                        marginTop: ['0', null, null, 'inc70'],
                        marginBottom: 'inc30',
                    }}
                >
                    Student Benefit FAQs
                </TypographyScale>
                <TypographyScale variant="body2">
                    Certifications offered by MongoDB are recognized by
                    professional institutions and the worldwide tech community,
                    as representing a mastery level in MongoDB applications. See
                    the FAQs below for instructions on how to redeem free
                    certification.
                </TypographyScale>
                <Button
                    size="small"
                    sx={{
                        margin: `${theme.space.inc40} auto ${theme.space.inc60} auto`,
                        width: 'unset',
                    }}
                    href={commonLinks.communityForumStudents}
                >
                    Student Community Forums
                </Button>
            </div>
            <div sx={{ maxWidth: '1680px', margin: 'auto' }}>
                <Accordion tabs={faqTabs} inverse={false} />
            </div>
            {!session?.user && (
                <TypographyScale
                    variant="body2"
                    sx={{ my: 'inc40', display: 'block' }}
                >
                    More information can be found on this page after entering
                    your GitHub credentials.
                </TypographyScale>
            )}
        </>
    );
};

export default Home;

export const getServerSideProps: GetServerSideProps = async context => {
    const session = await getSession(context);
    return {
        props: {
            session,
        },
    };
};
