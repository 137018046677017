const CardGrid: React.FunctionComponent<React.PropsWithChildren<{}>> = ({
    children,
}) => {
    return (
        <div
            sx={{
                maxWidth: '1200px',
                display: 'grid',
                gridGap: 'inc50',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: ['section50', null, null, 'section60'],
                gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))',
                gridAutoRows: '1fr',
                justifyContent: 'space-evenly',
                justifyItems: 'center',
                alignContent: 'space-evenly',
                alignItems: 'center',
            }}
        >
            {children}
        </div>
    );
};

export default CardGrid;
