import { ESingleImageVariant, IFlashCardProps } from '@mdb/flora';
import parseHelper from './utils/parseHelper';

const gtmId = 'GTM-GDFN';

const commonLinks = {
    promoCodeInstructions:
        'https://www.mongodb.com/docs/atlas/billing/subscriptions/#std-label-activate-subscription',
    communityForumStudents:
        'https://www.mongodb.com/community/forums/c/academia/students/42',
    startLearningWithUniversity:
        'https://learn.mongodb.com/?utm_campaign=academia_partners&utm_source=github_students&utm_medium=referral',
    learnMoreAboutAtlas:
        'https://www.mongodb.com/atlas/database?utm_campaign=academia&utm_source=github_students&utm_medium=referral',
    certification: 'https://learn.mongodb.com/pages/certification-program',
    certificationForm:
        'https://docs.google.com/forms/d/e/1FAIpQLSed69BbIECPWyaNjRfl2DN6ba3S8B8fY0V-Nhd9zuM0FD31PQ/viewform?usp=sf_link',
    certificationLearningPaths:
        'https://learn.mongodb.com/pages/certification-learning-paths',
    githubSdp: 'https://education.github.com/pack',
    students: 'https://mongodb.com/students',
};

interface FaqTab {
    header: string;
    description: string;
}

const faqTabs: FaqTab[] = [
    {
        header: 'Who is eligible for the MongoDB Student Benefits?',
        description: parseHelper(
            [
                'Our student benefits are available through the',
                'GitHub Student Developer Pack.',
                'If you are already successfully registered for the GitHub Student Developer Pack, you can access MongoDB student benefits by entering your GitHub credentials at',
                'mongodb.com/students.',
                'If you have questions regarding the',
                'GitHub Student Developer Pack,',
                'please contact GitHub support.',
            ],
            [
                {
                    type: 'link',
                    index: 1,
                    href: commonLinks.githubSdp,
                    target: '_blank',
                },
                {
                    type: 'link',
                    index: 3,
                    href: commonLinks.students,
                    target: '_blank',
                },
                {
                    type: 'link',
                    index: 5,
                    href: commonLinks.githubSdp,
                    target: '_blank',
                },
            ]
        ) as string,
    },
    {
        header: 'How do I access free MongoDB certification?',
        description: parseHelper(
            [
                'To receive a 100% certification discount voucher you must first verify your student status by entering your GitHub credentials above. Next, you must complete a MongoDB University',
                'Certification Learning Path.',
                'You will receive an email with a 100% discount voucher within 24 hours of completing an entire Certification Learning Path in MongoDB University.',
            ],
            [
                {
                    type: 'link',
                    index: 1,
                    href: commonLinks.certificationLearningPaths,
                    target: '_blank',
                },
            ]
        ) as string,
    },
    {
        header: 'How do I apply the Atlas promotional code for free credits? ',
        description: parseHelper(
            [
                "Once you've received your Atlas Promotional Code, you can apply the credits to your selected Atlas organization. To view instructions on How to Apply a Promo Code,",
                'visit this link.',
                'Please note, promotional codes are redeemed at the organization level. Applying your promotional code can only be redeemed',
                'once',
                'to an organization of your choice.',
            ],
            [
                {
                    type: 'link',
                    index: 1,
                    href: commonLinks.promoCodeInstructions,
                    target: '_blank',
                },
                {
                    type: 'strong',
                    index: 3,
                },
            ]
        ) as string,
    },
    {
        header: 'How many Atlas promotional codes will I receive?',
        description: 'Only one coupon code can be used per student.',
    },
    {
        header: 'Do the promotional codes expire?',
        description:
            "Atlas Promotional Codes will expire if they're not applied to your Atlas account within 90 days. You will be able to request a new code assuming you did not use the previous one and that you've received it less than 12 months ago.",
    },
    {
        header: 'How do Atlas credits work?',
        description:
            'MongoDB Atlas is billed hourly based on how much you use. As part of the GitHub Student Developer Pack, we offer $50 of Atlas credits to your Atlas organization. You will need a valid credit card or a PayPal account to redeem this offer. ',
    },
    {
        header: 'I have more questions! Who do I contact?',
        description: parseHelper(
            [
                'We have established a forum for questions about the program. Visit the',
                'Community forum page for Students',
                'for more information and to get your questions answered.',
            ],
            [
                {
                    type: 'link',
                    index: 1,
                    href: commonLinks.communityForumStudents,
                    target: '_blank',
                },
            ]
        ) as string,
    },
];

/* 
    These charts are displayed on student/stats. 
    * Note - the url references `githubsdp---staging` for all charts, including production. 
      The production urls are in fact displaying production data. `---staging` may be because the atlas project was previously named githubsdp-staging.
      The development urls are displaying dev data.
      The test urls are displaying staging data.
*/
const chartUrls = {
    production: {
        totalStudents:
            'https://charts.mongodb.com/charts-githubsdp---staging-hfhph/embed/charts?id=62f29efb-1798-4f33-884d-f5d5432cc26a&maxDataAge=3600&theme=light&autoRefresh=true',
        codesRemaining:
            'https://charts.mongodb.com/charts-githubsdp---staging-hfhph/embed/charts?id=62f29efb-1798-4b5f-8761-f5d5432cc26f&maxDataAge=3600&theme=light&autoRefresh=true',
        studentsWithoutCodes:
            'https://charts.mongodb.com/charts-githubsdp---staging-hfhph/embed/charts?id=62f29efb-1798-4b2b-891e-f5d5432cc26d&maxDataAge=3600&theme=light&autoRefresh=true',
        studentRegistrationsGraph:
            'https://charts.mongodb.com/charts-githubsdp---staging-hfhph/embed/charts?id=62f29efb-1798-4062-8ef1-f5d5432cc273&maxDataAge=3600&theme=light&autoRefresh=true',
    },
    development: {
        totalStudents:
            'https://charts.mongodb.com/charts-githubsdp---staging-hfhph/embed/charts?id=62fa65d5-4794-4f7b-84b2-140f436015ad&maxDataAge=3600&theme=light&autoRefresh=true',
        codesRemaining:
            'https://charts.mongodb.com/charts-githubsdp---staging-hfhph/embed/charts?id=62fa65d5-4794-485a-8fe9-140f436015b2&maxDataAge=3600&theme=light&autoRefresh=true',
        studentsWithoutCodes:
            'https://charts.mongodb.com/charts-githubsdp---staging-hfhph/embed/charts?id=62fa65d5-4794-48c4-8a76-140f436015b0&maxDataAge=3600&theme=light&autoRefresh=true',
        studentRegistrationsGraph:
            'https://charts.mongodb.com/charts-githubsdp---staging-hfhph/embed/charts?id=62fa65d5-4794-4f58-801c-140f436015b6&maxDataAge=3600&theme=light&autoRefresh=true',
    },
    test: {
        totalStudents:
            'https://charts.mongodb.com/charts-githubsdp---staging-hfhph/embed/charts?id=62f29eb8-5496-4168-8f1c-6d3ebf6aca57&maxDataAge=3600&theme=light&autoRefresh=true',
        codesRemaining:
            'https://charts.mongodb.com/charts-githubsdp---staging-hfhph/embed/charts?id=62f29eb8-5496-4453-83fe-6d3ebf6aca5b&maxDataAge=3600&theme=light&autoRefresh=true',
        studentsWithoutCodes:
            'https://charts.mongodb.com/charts-githubsdp---staging-hfhph/embed/charts?id=62f29eb8-5496-4acb-83de-6d3ebf6aca59&maxDataAge=3600&theme=light&autoRefresh=true',
        studentRegistrationsGraph:
            'https://charts.mongodb.com/charts-githubsdp---staging-hfhph/embed/charts?id=62f29eb8-5496-4403-8dd3-6d3ebf6aca5f&maxDataAge=3600&theme=light&autoRefresh=true',
    },
};

const featureProjectImageVariant = ESingleImageVariant.VIDEO;
const featureProjectCards: IFlashCardProps[] = [
    {
        title: 'Schulkit',
        text: 'Schulkit, powered by MongoDB Atlas, is an app designed to streamline and manage your school stuff the Schul way. This app utilizes MongoDB Atlas, MongoDB Charts, and Nuxt.',
        cta: {
            type: 'link-arrow',
            text: 'Learn More',
            config: {
                href: 'https://devpost.com/software/schulkit',
            },
        },
        imageConfig: {
            variant: featureProjectImageVariant,
            src: 'https://gh-student-pack-images.s3.amazonaws.com/schulkit.png',
        },
    },
    {
        title: 'A Spotify Song and Playlist Recommendation Engine',
        text: 'This app is a Spotify song and playlist recommendation engine. Apache Spark, MongoDB, Amazon S3, and Databricks (PySpark) were used to train a deep-learning Word2Vec model to build song and playlist embeddings for recommendation. ',
        cta: {
            type: 'link-arrow',
            text: 'Learn More',
            config: {
                href: 'https://www.mongodb.com/developer/code-examples/python/song-recommendations-example-app/',
            },
        },
        imageConfig: {
            variant: featureProjectImageVariant,
            src: 'https://www.mongodb.com/developer/_next/image/?url=https%3A%2F%2Fimages.contentstack.io%2Fv3%2Fassets%2Fblt39790b633ee0d5a7%2Fbltc5f21012ab39822d%2F654bd126a1a3d4040a7523eb%2Fa-spotify-song-and-playlist-recommendation-engine.png&w=3840&q=75',
        },
    },
    {
        title: 'EdWiz',
        text: 'This is a browser-based app that allows educators to better prepare for their lessons by using physical gestures to create visualization. It is an ML-enabled full-stack app built with Next.js, Prisma, React, and Typescript.',
        cta: {
            type: 'link-arrow',
            text: 'View on Major League Hacking',
            config: {
                href: 'https://devpost.com/software/edwiz',
            },
        },
        imageConfig: {
            variant: featureProjectImageVariant,
            src: 'https://i.postimg.cc/8CmYxpnC/Screenshot-2023-01-08-220941.png',
        },
    },
    {
        title: 'Example Application for Dog Care Providers (DCP)',
        text: 'This is an example of an app aiming to modernize the existing SQL database used by the Dog Welfare Federation (DWF). This project includes a Beginner’s Guide to MongoDB that allows others to follow along step-by-step.',
        cta: {
            type: 'link-arrow',
            text: 'Learn More',
            config: {
                href: 'https://www.mongodb.com/developer/code-examples/python/dog-care-example-app/',
            },
        },
        imageConfig: {
            variant: featureProjectImageVariant,
            src: 'https://images.contentstack.io/v3/assets/blt39790b633ee0d5a7/blt5fdefe5bcbe0add1/647a2ec081713c3089e3ccdf/dcp.jpg',
        },
    },
    {
        title: 'Harvest Hero',
        text: `A significant amount of edible and nutritious food goes to waste because it's not harvested. This app was built as a solution to combat the growing issue of food waste and uses MERN stack, Twilio, Express.
js, and Node.js.`,
        cta: {
            type: 'link-arrow',
            text: 'Learn More',
            config: {
                href: 'https://devpost.com/software/harvest-hero',
            },
        },
        imageConfig: {
            variant: featureProjectImageVariant,
            src: 'https://gh-student-pack-images.s3.amazonaws.com/harvest-hero.png',
        },
    },
];

export { chartUrls, commonLinks, faqTabs, gtmId, featureProjectCards };
