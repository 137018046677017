import BaseIcon, { BaseIconProps } from './BaseIcon';

export const Copy: React.FunctionComponent<BaseIconProps> = ({ className }) => {
    return (
        <BaseIcon className={className}>
            <path
                d="M0 9.42857V18.5714C0 20.465 1.53502 22 3.42857 22H9.5V12.2191C9.5 11.0883 9.95449 10.0049 10.7613 9.21247L15.3481 4.70761C15.5489 4.51032 15.7675 4.33515 16 4.18363V3.42857C16 1.53502 14.465 0 12.5714 0H9.6V6.85714C9.6 8.2773 8.44874 9.42857 7.02857 9.42857H0Z"
                fill="#21313C"
            />
            <path
                d="M12.5131 10.9961C12.4938 11.015 12.475 11.0344 12.4567 11.0542C12.4442 11.0677 12.4319 11.0814 12.4198 11.0952C12.1501 11.4059 12 11.8047 12 12.2191V13.5952H18.019C18.9658 13.5952 19.7333 12.8277 19.7333 11.8809V6H18.3011C17.8517 6 17.4204 6.1764 17.0998 6.49123L12.5131 10.9961Z"
                fill="#21313C"
            />
            <path
                d="M7.73333 0H6.30105C5.85174 0 5.42039 0.176397 5.09984 0.491231L0.513071 4.99609C0.184877 5.31842 0 5.75913 0 6.21914V7.59524H6.01905C6.96582 7.59524 7.73333 6.82772 7.73333 5.88095V0Z"
                fill="#21313C"
            />
            <path
                d="M12 15.4286H19.0286C20.4487 15.4286 21.6 14.2773 21.6 12.8571V6H24.5714C26.465 6 28 7.53502 28 9.42857V24.5714C28 26.465 26.465 28 24.5714 28H15.4286C13.535 28 12 26.465 12 24.5714V15.4286Z"
                fill="#21313C"
            />
        </BaseIcon>
    );
};
